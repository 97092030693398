import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

export default class Slider extends React.Component {
  state = {
    slide: this.props.content[0],
  }

  setSlide = (clicked) => {
    this.setState({ slide: clicked })
  }

  nextSlide = (dir) => {
    var i = this.props.content.indexOf(this.state.slide)
    var next = i + dir
    if (next > 2 || next < 0) next = 1 - dir
    this.setState({ slide: this.props.content[next] })
  }

  render() {
    return (
      <div>
        <div className="flex flex--v-center">
          <FontAwesomeIcon
            className="arrows arrows--left desktop-only"
            icon={faChevronLeft}
            onClick={() => this.nextSlide(-1)}
          />
          {this.props.content.map((slide) => (
            <div
              className={`gray-background slider fade ${
                this.state.slide == slide && 'slider__show'
              }`}
              dangerouslySetInnerHTML={{
                __html: slide.text,
              }}
            ></div>
          ))}
          <FontAwesomeIcon
            className="arrows arrows--right desktop-only"
            icon={faChevronRight}
            onClick={() => this.nextSlide(1)}
          />
        </div>
        <div className="flex flex--h-center slider--center margin-top-small">
          {this.props.content.map((item, i) => (
            <span
              onClick={() => this.setSlide(item)}
              className={`dot slider--center ${
                this.state.slide == item && 'dot__clicked'
              }`}
            ></span>
          ))}
        </div>
      </div>
    )
  }
}
