import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'
import Hero from '../../components/Hero'

import Layout from '../../components/Layout'
import ShorterContent from '../../components/ShorterContent'
import VideoNews from '../../components/VideoNews'
import Link from '../../components/Link'
import Slider from '../../components/Slider'
import VideoSlider from '../../components/VideoSlider'
import Footer from '../../components/Footer'
import Menu from '../../components/Menu'
import LocaleContext from '../../contexts/LocaleContext'

export default function AboutNA({ data, pageContext }) {
  const {
    ourMission,
    mapImage,
    strategy,
    videoTitle,
    video,
    sliderDescription,
    slider,
    textbox,
    followUpAndEvaluation,
    detailsTitle,
    details,
    collaboratingAnalysts,
    heroText,
    heroBackground,
  } = data.datoCmsAboutNordicAsiaPage

  return (
    <Layout transparentMenu locale={pageContext.locale}>
      <Menu
        transparent={true}
        menu={data.datoCmsNewMenu}
        socialMedia={data.datoCmsFooter.socialMedia}
      />
      <React.Fragment>
        <HelmetDatoCms>
          <title>
            Nordic Asia - Investment Advisory Group | About Nordic Asia
          </title>
        </HelmetDatoCms>
        <Hero heroBackground={heroBackground} className="portfolio-hero">
          <div
            dangerouslySetInnerHTML={{
              __html: heroText,
            }}
          />
        </Hero>

        <section className="container center-width prose margin-top-smaller">
          <ShorterContent>
            <div
              dangerouslySetInnerHTML={{
                __html: ourMission,
              }}
            ></div>
          </ShorterContent>
        </section>

        <section className="gray-background">
          <div className="container center-width ">
            <ShorterContent>
              <img className="image-centre" src={mapImage.fixed.src} />
            </ShorterContent>
          </div>
        </section>

        <div className="container center-width prose">
          <div
            dangerouslySetInnerHTML={{
              __html: detailsTitle,
            }}
          ></div>

          <section>
            <div className="responsive-columns margin-top-big">
              {details.map((item) => (
                <div
                  className=" margin-left-small margin-right-small"
                  dangerouslySetInnerHTML={{
                    __html: item.text,
                  }}
                ></div>
              ))}
            </div>
          </section>
        </div>

        <section className="gray-background">
          <div className="container center-width prose margin-top-small">
            <ShorterContent>
              <div
                dangerouslySetInnerHTML={{
                  __html: strategy,
                }}
              ></div>
            </ShorterContent>
          </div>
        </section>

        {/* {slider && (
          <section className="container center-width prose">
            <ShorterContent>
              <div
                dangerouslySetInnerHTML={{
                  __html: sliderDescription,
                }}
              ></div>
              <Slider className="margin-top-small" content={slider} />
            </ShorterContent>
            <PortfolioButton />
          </section>
        )} */}

        {textbox && (
          <section className="container center-width prose">
            <ShorterContent>
              <div
                dangerouslySetInnerHTML={{
                  __html: textbox,
                }}
              ></div>
            </ShorterContent>

            <ShorterContent>
              <div
                dangerouslySetInnerHTML={{
                  __html: followUpAndEvaluation,
                }}
              ></div>
            </ShorterContent>
          </section>
        )}

        {video[0].oembedCode && (
          <div className="gray-background">
            <section className="container center-width prose ">
              <h2 className="flex flex--h-center">{videoTitle}</h2>
              <VideoSlider className="margin-top-small" content={video} />
            </section>
          </div>
        )}

        <div className="container center-width prose margin-top-small">
          <ShorterContent>
            <div
              className="partners-image-centre"
              dangerouslySetInnerHTML={{
                __html: collaboratingAnalysts,
              }}
            ></div>
          </ShorterContent>
        </div>
        <Footer footer={data.datoCmsFooter} />
      </React.Fragment>
    </Layout>
  )
}

const PortfolioButton = () => {
  const locale = React.useContext(LocaleContext).language
  return (
    <div className="flex flex--h-center slider--center">
      <Link className="btn margin-top flex--h-center" skipLocalePrefix={true}
      to={locale=="en" ? '/en/portfolio/portfolioHoldings' : '/portfolio/portfolioHoldings'}>
        {locale=="sv" ? 'Läs mer om portföljen' : 'Read more about our portfolio'}
      </Link >
    </div>
  )
}

export const query = graphql`
  query ($locale: String) {
    datoCmsAboutNordicAsiaPage (locale: { eq: $locale }){
      heroText
      heroBackground {
        fluid(maxWidth: 2560, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      ourMission
      mapImage {
        fixed(width: 800) {
          ...GatsbyDatoCmsFixed
        }
      }
      strategy
      textbox
      followUpAndEvaluation
      detailsTitle
      details {
        ... on DatoCmsHtmlText {
          text
        }
      }
      sliderDescription
      slider {
        text
      }
      videoTitle
      video {
        id
        spotifyLink
        videoLink
        oembedCode
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      collaboratingAnalysts
    }
    datoCmsNewMenu (locale: { eq: $locale }) {
      ...Menu
    }
    datoCmsFooter (locale: { eq: $locale }) {
      ...Footer
    }
  }
`
