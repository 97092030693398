import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocaleContext from '../contexts/LocaleContext'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

export default class Slider extends React.Component {
  static contextType = LocaleContext
  state = {
    slide: this.props.content[0],
  }

  setSlide = (clicked) => {
    this.setState({ slide: clicked })
  }

  nextSlide = (dir) => {
    var i = this.props.content.indexOf(this.state.slide)
    var next = i + dir
    if (next > 2 || next < 0) next = 1 - dir
    this.setState({ slide: this.props.content[next] })
  }

  render() {
    return (
      <div>
        <div className="flex flex--h-center">
          <FontAwesomeIcon
            className="arrows arrows--vcenter desktop-only"
            icon={faChevronLeft}
            onClick={() => this.nextSlide(-1)}
          />
          {this.props.content.map((slide) => (
            <div
              className={`slider fade ${
                this.state.slide == slide && 'slider__show'
              }`}
            >
              <div
                className={`col flex flex--column video-news__item`}
                key={slide.videoLink}
              >
                <div className="flex flex--v-center">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: slide.oembedCode,
                    }}
                    className="video-news__container"
                  ></div>
                </div>
                <div
                  className="small margin-top-small"
                  dangerouslySetInnerHTML={{
                    __html: slide.descriptionNode.childMarkdownRemark.html,
                  }}
                ></div>
                <div
                  className="toolstrip toolstrip--no-margin toolstrip--md small"
                  style={{ marginTop: 'auto' }}
                >
                  {slide.spotifyLink && (
                    <a
                      className=" btn btn--outline full-width"
                      href={slide.spotifyLink}
                    >
                      {this.context.language=="sv" ? 'Lyssna på Spotify' : 'Listen on Spotify'}
                    </a>
                  )}
                  <a
                    className={`btn full-width ${
                      !slide.spotifyLink && 'btn--outline'
                    }`}
                    href={slide.videoLink}
                  >
                    {this.context.language=="sv" ? 'Titta här' : 'Watch here'}
                  </a>
                </div>
              </div>
            </div>
          ))}
          <FontAwesomeIcon
            className="arrows arrows--vcenter desktop-only"
            icon={faChevronRight}
            onClick={() => this.nextSlide(1)}
          />
        </div>
        <div className="flex flex--h-center margin-top-small">
          {this.props.content.map((item, i) => (
            <span
              onClick={() => this.setSlide(item)}
              className={`dot slider--center ${
                this.state.slide == item && 'dot__clicked'
              }`}
            ></span>
          ))}
        </div>
      </div>
    )
  }
}
